import { useContext } from 'react';
import { GlobalContext } from '../components/data/GlobalContext';

import { Container } from 'react-bootstrap';
import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";
import CardService from "../components/services/CardService";

import { GiPaintRoller } from "react-icons/gi";
import {MdOutlineRoofing} from "react-icons/md";


const Services = () => {

    const dbData = useContext(GlobalContext);

    return (
        <Layout pageTitle="Services">
            <PagesHeader title="Services" crumbTitle="Services" pageHeaderBg={dbData.stock[0]} />
            <Container>
                <div className='d-flex justify-content-center align-items-center py-5'>
                    <MdOutlineRoofing fontSize={50} color="red" className='me-3' />
                    <h1 className='text-capitalize fw-bold'> our services</h1>
                    <MdOutlineRoofing fontSize={50} color="red" className='ms-3' />
                </div>
            </Container>
            <Container className="pb-120">
                <CardService />
            </Container>
        </Layout>
    );
}
export default Services;